<template>
  <b-container fluid class="account-settings mt-3">
    <b-row>
      <b-col cols="4">
        <b-card
          header="SIP settings"
          header-tag="header"
        >
          <SipSettings></SipSettings>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SipSettings from '@/components/settings/account/SipSettings'
export default {
  name: 'AccountSettings',
  components: { SipSettings }
}
</script>

<style scoped>
  .account-settings .card header {
    font-weight: bold;
    font-size: large;
  }
</style>
